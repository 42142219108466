define("semente-web-app/models/instituicao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    enabled: _emberData.default.attr(),
    deleted: _emberData.default.attr(),
    timestamps: _emberData.default.attr(),
    acessos: _emberData.default.attr(),
    ativcompletas: _emberData.default.attr(),
    nrgestores: _emberData.default.attr(),
    nrgestoresAtivos: _emberData.default.attr(),
    nralunos: _emberData.default.attr(),
    nralunosAtivos: _emberData.default.attr(),
    nrinstrutores: _emberData.default.attr(),
    nrinstrutoresAtivos: _emberData.default.attr(),
    nrcoordenadores: _emberData.default.attr(),
    nrcoordenadoresAtivos: _emberData.default.attr(),
    nrsecretaria: _emberData.default.attr(),
    nrsecretariaAtivos: _emberData.default.attr(),
    nrmarketing: _emberData.default.attr(),
    nrmarketingAtivos: _emberData.default.attr(),
    trocasenhaobrigatoria: _emberData.default.attr(),
    temBiblioteca: _emberData.default.attr(),
    uf: _emberData.default.attr(),
    cidade: _emberData.default.attr(),
    areas: _emberData.default.hasMany('area', {
      async: true
    }),
    turmas: _emberData.default.hasMany('turma', {
      async: true
    }),
    pessoas: _emberData.default.hasMany('pessoa', {
      async: true
    }),
    modulos: _emberData.default.hasMany('modulo', {
      async: true
    }),
    sistemas: _emberData.default.hasMany('sistema', {
      async: true
    }),
    acompanhamentosatividades: _emberData.default.hasMany('acompanhamento-atividade-instituicao', {
      async: true
    }),
    acompanhamentosCursoInstituicao: _emberData.default.hasMany('acompanhamento-curso-instituicao', {
      async: true
    }),
    instituicaoFilhas: _emberData.default.hasMany('instituicao', {
      async: true
    }),
    plataformaAnos: _emberData.default.hasMany('plataforma-ano', {
      async: true
    }),
    plataformaTurmas: _emberData.default.hasMany('plataforma-turma', {
      async: true
    }),
    ignoraCalendarioMedio: _emberData.default.attr(),
    isEscola: _emberData.default.attr(),
    radical: _emberData.default.attr(),
    statusTermoAceite: _emberData.default.attr(),
    codigoProfessores: _emberData.default.attr(),
    codigoAlunos: _emberData.default.attr(),
    codigoAlunosInfantil: _emberData.default.attr(),
    codigosCadastro: _emberData.default.hasMany('codigo-cadastro', {
      async: true
    }),
    sEnabled: _emberData.default.attr(),
    sPlusEnabled: _emberData.default.attr(),
    essencialEnabled: _emberData.default.attr(),
    csEnabled: _emberData.default.attr(),
    calendario: _emberData.default.belongsTo('calendario', {
      async: true
    }),
    vendaDireta: _emberData.default.attr(),
    instituicaoPlataformaAnoSistema: _emberData.default.hasMany('instituicao-plataforma-ano-sistema', {
      async: true
    }),
    status: _emberData.default.attr(),
    baseUpdated: _emberData.default.attr()
  });

  _exports.default = _default;
});